<template>
  <div class="management">
    <div class="management-left">
      <div class="btn-box">
        <el-button
          type="primary"
          class="el-icon-plus"
          size="small"
          @click="addProgramme"
        >
          添加方案
        </el-button>
      </div>

      <div class="management-list" v-loading="leftLoading">
        <div
          v-infinite-scroll="leftLoad"
          :infinite-scroll-disabled="leftInfiniteType"
          :infinite-scroll-distance="10"
        >
          <el-collapse accordion @change="viewData">
            <el-collapse-item
              class="collapse-item-box"
              :class="selectIndex === item.id ? 'select-collapse' : ''"
              v-for="(item, i) in leftList"
              :key="i"
              :name="item.id"
            >
              <template slot="title">
                <div class="collapse-head">
                  <div>
                    <span>{{ item.name }}</span>
                  </div>
                  <div>
                    <el-popover
                      placement="bottom"
                      trigger="hover"
                      popper-class="management-popover"
                    >
                      <div>
                        <div>
                          <el-button
                            type="text"
                            class="el-icon-edit-outline"
                            size="mini"
                            @click="update(item)"
                          >
                            编辑
                          </el-button>
                        </div>

                        <div style="margin-top:5px">
                          <el-button
                            type="text"
                            class="el-icon-delete"
                            size="mini"
                            style="color:red"
                            @click="delProgramme(item.id, i)"
                          >
                            删除
                          </el-button>
                        </div>
                      </div>
                      <span slot="reference" class="popover-title">
                        <img :src="require('@/assets/imgs/i7.png')" alt="" />
                      </span>
                    </el-popover>
                  </div>
                </div>
              </template>
              <div class="management-item">
                <p
                  v-for="(ele, j) in item.tasks"
                  :key="i + j"
                  @click="getlistinfo(ele, j)"
                  :class="idx == ele.id ? 'active' : ''"
                >
                  <img
                    v-if="ele.state == 0"
                    :src="require('@/assets/imgs/p1.png')"
                    alt=""
                    title="待处理"
                  />
                  <img
                    v-else-if="ele.state == -1"
                    :src="require('@/assets/imgs/p4.png')"
                    alt=""
                    title="用户不存在"
                  />
                  <img
                    v-else-if="ele.state == 1"
                    :src="require('@/assets/imgs/p3.png')"
                    alt=""
                    title="正常"
                  />
                  <span>{{ ele.target }}</span>

                  <el-popover placement="right" trigger="hover">
                    <div>
                      {{ ele.remark }}
                    </div>

                    <i
                      v-if="ele.remark"
                      slot="reference"
                      class="el-icon-info"
                    ></i>
                  </el-popover>
                </p>
              </div>
              <div class="management-time">
                创建时间： {{ item.create_time | capitalize }}
              </div>
            </el-collapse-item>
          </el-collapse>

          <noMorePage :nomore="leftNomore" v-if="leftList.length > 0" />
        </div>
        <no-data
          v-show="leftList.length == 0"
          text="暂无方案"
          fontSize="14px"
        />
      </div>
    </div>
    <div class="management-right" v-loading="rightLoading">
      <totalAll
        v-if="rightList.length > 0"
        :text="'共检索到数据' + taskPage.total + '条'"
      />

      <div
        v-infinite-scroll="rightLoad"
        :infinite-scroll-disabled="rightDisabled"
        :infinite-scroll-distance="10"
        class="management-right-child"
      >
        <div class="right-item" v-for="(item, i) in rightList" :key="i">
          <div class="right-item-title">
            <div class="title-left">
              <img
                :src="
                  item.user
                    ? item.user.avatar
                      ? item.user.avatar
                      : require('@/assets/imgs/p2.png')
                    : require('@/assets/imgs/p2.png')
                "
                alt=""
              />
              <span>
                {{
                  item.user
                    ? item.user.nick_name
                      ? item.user.nick_name
                      : item.user.user_name
                    : '未知'
                }}
              </span>
              &nbsp;
              <span>{{ item.create_time | capitalize }}</span>
            </div>
            <div class="title-right">帖子ID：{{ item.story_fbid }}</div>
          </div>
          <div class="right-item-content" v-html="item.content"></div>
          <div v-if="item.media" class="right-item-media">
            <img
              :src="imgItem"
              v-for="(imgItem, i) in item.media"
              :key="i"
              alt=""
              :onerror="noPic"
            />
          </div>
        </div>
        <noMorePage :nomore="rightNomore" v-if="rightList.length > 0" />
      </div>

      <no-data v-if="rightList.length == 0" text="暂无数据" />
    </div>

    <!--  -->
    <el-dialog
      :title="dialogTitle == 1 ? '添加方案' : '编辑方案'"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="closeDialog"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="方案名称：" prop="name">
            <el-input
              placeholder="方案名称"
              v-model.trim="ruleForm.name"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="方案内容：">
            <div class="task-form-list" v-for="(item, i) in tasklist" :key="i">
              <el-input
                v-model.trim="item.name"
                placeholder="添加用户url"
                size="small"
                class="task-input"
                :disabled="dialogTitle == 0 && item.id ? true : false"
              ></el-input>
              <el-input
                v-model.trim="item.remark"
                placeholder="备注"
                size="small"
                class="remark-input"
                :disabled="dialogTitle == 0 && item.id ? true : false"
              ></el-input>

              <el-button
                class="task-btn"
                size="small"
                @click="setTask(item, i)"
                type="primary"
                v-if="dialogTitle == 0 && !item.id"
              >
                保存
              </el-button>
              <el-button
                class="task-btn"
                size="small"
                @click="delTask(item, i)"
                v-if="dialogTitle == 0 && item.id"
              >
                删除
              </el-button>
              <el-button
                class="task-btn"
                size="small"
                @click="delTask(item, i)"
                v-if="dialogTitle == 1"
              >
                删除
              </el-button>
            </div>
            <el-button
              @click="addTask"
              type="primary"
              class="el-icon-plus"
              size="small"
            >
              新增内容
            </el-button>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              placeholder="备注"
              v-model.trim="ruleForm.remark"
              type="textarea"
              size="small"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" style="textAlign:center">
        <el-button
          v-if="dialogTitle == 1"
          type="primary"
          @click="sureProgramme"
          size="small"
        >
          提交方案
        </el-button>
        <el-button
          v-if="dialogTitle == 0"
          type="primary"
          @click="updateProgramme"
          size="small"
        >
          更新方案
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addScheme,
  getScheme,
  getArticles,
  delScheme,
  updateScheme,
  delTask,
  setTask,
  getTask
} from '@/api/management.js'

export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      infiniteType: false,
      ruleForm: {
        name: '',
        remark: ''
      },
      selectIndex: null,
      tasklist: [{ name: '' }],
      dialogVisible: false,
      dialogTitle: 1,
      idx: null,
      rules: {
        name: [
          { required: true, message: '请输入方案名称', trigger: 'blur' },
          { min: 1, message: '方案名称不能为空', trigger: 'blur' }
        ]
      },
      // 方案分页
      programmePage: {
        current: 1,
        limit: 50,
        total: 0
      },
      leftLoading: true,
      leftNomore: true,
      leftInfiniteType: false,
      leftList: [],

      // 文章分页
      taskPage: {
        current: 1,
        limit: 40,
        total: 0
      },
      rightLoading: false,
      rightDisabled: false,
      rightNomore: true,
      rightList: [],
      // 选中
      selectObj: {}
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return ''
      let arr = value.split('+')
      return arr[0]
    }
  },
  created() {
    // this.getPage()
  },
  methods: {
    closeDialog(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    // 获取点击左侧某个方案任务信息
    getlistinfo(row, j) {
      this.idx = row.id
      const h = this.$createElement
      if (row.state == 1) {
        if (row.last_run_time !== null) {
          let lasttime = row.last_run_time
          lasttime = lasttime.slice(0, lasttime.length - 5)
          this.$notify({
            title: '最新采集时间：',
            message: h('i', { style: 'color: red' }, lasttime)
          })
        } else {
          this.$notify({
            title: '最新采集时间：',
            message: h(
              'i',
              { style: 'color: red' },
              '新增方案，数据采集中，请稍后再试！'
            )
          })
        }
      } else if (row.state == 0) {
        this.$notify({
          title: '最新采集时间：',
          //message: row.lastMonitorTime,
          message: h(
            'i',
            { style: 'color: red' },
            '新增方案，数据采集中，请稍后再试！'
            //this.selectObj.lastMonitorTime
          )
        })
      } else if (row.state == -1) {
        this.$notify({
          title: '最新采集时间：',
          //message: row.lastMonitorTime,
          message: h(
            'i',
            { style: 'color: red' },
            '名称错误，采集失败'
            //this.selectObj.lastMonitorTime
          )
        })
      }
      this.taskPage.current = 1
      this.rightList = []
      this.rightLoad()
    },
    leftLoad() {
      this.rightDisabled = true
      this.getTranslate().then(() => {
        if (this.leftList.length > 0) {
          if (!this.selectIndex) {
            this.selectIndex = this.leftList[0].id
            this.rightList = []
            this.taskPage.current = 1
            this.rightLoad()
          }
        }
      })
    },
    // 左侧加载
    getTranslate() {
      return new Promise((resolver, reject) => {
        this.leftLoading = true
        this.leftInfiniteType = true
        let obj = {
          page: this.programmePage.current,
          page_size: this.programmePage.limit,
          collection_type: 2
        }
        getScheme(obj).then(res => {
          if (res) {
            if (res.data.data.length > 0) {
              this.leftInfiniteType = false
              this.programmePage.current++
              this.leftList = this.leftList.concat(res.data.data)
              if (res.data.data.length < this.programmePage.limit) {
                this.leftNomore = false
              }
            } else {
              this.leftNomore = false
            }
            resolver()
          } else {
            this.leftNomore = false
          }
          this.leftLoading = false
        })
      })
    },
    // 右侧加载
    rightLoad() {
      let obj = {
        page: this.taskPage.current,
        page_size: this.taskPage.limit,
        task_ids: this.idx ? this.idx : null
      }
      this.rightLoading = true
      this.rightDisabled = true
      getArticles(this.selectIndex, obj).then(res => {
        if (res) {
          this.taskPage.total = res.data.count
          if (res.data.data.length > 0) {
            this.rightDisabled = false
            this.taskPage.current++
            this.rightList = this.rightList.concat(res.data.data)
            if (res.data.data.length < this.taskPage.limit) {
              this.rightNomore = false
            }
          } else {
            this.rightNomore = false
          }
        } else {
          this.rightNomore = false
        }
        this.rightLoading = false
      })
    },

    addProgramme() {
      this.dialogVisible = true
      this.dialogTitle = 1
      this.ruleForm.name = ''
      this.ruleForm.remark = ''
      this.tasklist = [{ name: '' }]
    },
    // 更新打开弹框
    update(row) {
      this.dialogVisible = true
      this.dialogTitle = 0
      this.ruleForm = Object.assign({}, row)
      this.tasklist = row.tasks.map(item => {
        item.name = item.target
        return item
      })
    },
    // 更新
    updateProgramme() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = {}
          obj.name = this.ruleForm.name
          obj.remark = this.ruleForm.remark

          updateScheme(this.ruleForm.id, obj).then(res => {
            if (res.status == 200) {
              this.leftList.forEach((item, i) => {
                if (item.id === res.data.id) {
                  this.leftList.splice(i, 1, res.data)
                }
              })
              this.$message({
                type: 'success',
                message: '更新成功！'
              })
              this.dialogVisible = false

              // this.taskPage.current = 1
              // this.rightList = []
              // this.rightLoad()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 添加任务数据
    addTask() {
      this.tasklist.unshift({
        name: ''
      })
    },
    // 保存单个任务
    setTask(row, i) {
      let memo
      if (row.remark && row.remark.trim() !== '') {
        memo = row.remark
      } else {
        memo = row.name
      }
      let reg = /^https:\/\//
      if (!reg.test(row.name)) {
        this.$message({
          type: 'warning',
          message: '请检查用户url是否规范！'
        })
        return
      }
      let obj = {
        target: row.name,
        remark: memo,
        target_type: 3
      }
      setTask(this.ruleForm.id, obj).then(res => {
        if (res.status == 201) {
          this.$message({
            type: 'success',
            message: '已保存！'
          })
          res.data.name = res.data.target
          this.tasklist.splice(i, 1, res.data)
          this.leftList.forEach((item, i) => {
            if (item.id == this.ruleForm.id) {
              item.tasks.unshift(res.data)
            }
          })
          if (!this.idx) {
            this.taskPage.current = 1
            this.rightList = []
            this.rightLoad()
          }
        } else {
          this.$message({
            type: 'error',
            message: '保存失败！'
          })
        }
      })
    },
    // 删除任务数据
    delTask(row, i) {
      if (this.tasklist.length < 2) return
      if (row.id) {
        delTask(this.ruleForm.id, row.id).then(res => {
          if (res.status == 204) {
            this.tasklist.splice(i, 1)
            this.$message({
              type: 'success',
              message: '删除成功！'
            })

            this.leftList.forEach((item, i) => {
              if (item.id == this.ruleForm.id) {
                item.tasks.forEach((ele, j) => {
                  if (ele.id == row.id) {
                    item.tasks.splice(j, 1)
                  }
                })
              }
            })
            if (!this.idx) {
              this.taskPage.current = 1
              this.rightList = []
              this.rightLoad()
            } else if (row.id === this.idx) {
              this.idx = null
              this.taskPage.current = 1
              this.rightList = []
              this.rightLoad()
            }
          }
        })
      } else {
        this.tasklist.splice(i, 1)
      }
    },
    // 弹框确定
    sureProgramme() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = Object.assign({}, this.ruleForm)
          obj.collection_type = 2
          let arr = this.tasklist.map(item => {
            let memo
            if (item.remark && item.remark.trim() !== '') {
              memo = item.remark
            } else {
              memo = item.name
            }
            item.remark = memo
            item.target_type = 3
            item.target = item.name.trim()
            return item
          })
          if (
            arr.some(item => {
              return item.target == ''
            })
          ) {
            this.$message({
              type: 'warning',
              message: '用户url不能为空！'
            })
            return
          }
          let reg = /^https:\/\//
          for (let i = 0; i < arr.length; i++) {
            if (!reg.test(arr[i].target)) {
              this.$message({
                type: 'warning',
                message: '请检查用户url是否规范！'
              })
              return
            }
          }

          obj.tasks = arr
          this.postScheme(obj).then(res => {
            if (res.status == 201) {
              this.$message({
                type: 'success',
                message: '添加成功！'
              })
              this.dialogVisible = false
              //
              this.programmePage.current = 1
              this.leftList = []
              this.leftLoad()
              this.idx = null
              this.selectIndex = null
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    postScheme(data) {
      // 添加方案接口
      return new Promise((resolve, reject) => {
        addScheme(data).then(res => {
          resolve(res)
        })
      })
    },
    // 删除方案
    delProgramme(id, index) {
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delScheme(id).then(res => {
            if (res.status == 204) {
              // this.leftList.splice(index, 1)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })

              this.programmePage.current = 1
              this.leftList = []
              this.leftLoad()
              this.idx = null
              this.selectIndex = null
            } else {
              this.$message({
                type: 'warning',
                message: '删除失败!'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    // 点击方案查看数据
    viewData(i) {
      if (i !== '') {
        const k = this.leftList.findIndex(item => item == i)
        this.idx = null
        this.selectIndex = i
        this.taskPage.current = 1
        this.rightList = []
        this.rightLoad()
      }
    }
  }
}
</script>

<style lang="scss">
.management {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .management-left {
    width: 260px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    .btn-box {
      text-align: center;
      button {
        width: 100%;
        border: 1px solid #ebeced;
        border-radius: 18px;
      }
    }
    .management-list {
      margin-top: 15px;
      width: 100%;
      height: calc(100% - 50px);
      // height: 100px;
      overflow: auto;
      position: relative;
      .collapse-item-box {
        margin: 10px 0;
        border: 1px solid #ebeef5;
        padding: 0 5px;
      }
      .select-collapse {
        border: 1px solid #409eff;
      }
      .collapse-head {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      .management-item {
        border-top: 1px solid #ebeced;
        padding: 5px;
        p {
          margin: 5px 0;
          font-size: 12px;
          font-weight: 300;
          color: #1a1a1b;
          cursor: pointer;
          border-bottom: 1px dashed #eee;
          line-height: 30px;
          &:hover {
            span {
              color: blue;
            }
          }
          &.active {
            span {
              color: #1b74e4;
              font-weight: bold;
            }
          }
          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }
          span {
            display: inline-block;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
            margin-left: 5px;
            line-height: 20px;
          }
          .el-icon-info {
            font-size: 14px;
            vertical-align: middle;
            margin-left: 5px;
          }
        }
      }
      .management-time {
        border-top: 1px solid #ebeced;
        font-size: 12px;
        font-weight: 300;
        color: #1a1a1b;
        line-height: 28px;
        padding: 5px;
      }
      // 折叠面板
      .el-collapse {
        border-bottom: none;
      }
      .el-collapse-item__arrow {
        display: none;
      }
      .el-collapse-item__header {
        height: 40px;
        line-height: 40px;
        padding: 0 5px;
        border-bottom: none;
      }
      .el-collapse-item__content {
        padding-bottom: 0;
      }
      .el-collapse-item__wrap {
        border-bottom: 0;
      }
    }
  }
  .management-right {
    width: calc(100% - 270px);
    height: 100%;
    overflow: hidden;
    position: relative;
    > .management-right-child {
      width: 100%;
      height: calc(100% - 40px);
      overflow: auto;
      margin-top: 10px;
    }
    .right-item {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 0px 10px 0px rgba(124, 124, 124, 0.2);
      border-radius: 5px;
      padding: 10px 20px;
      // overflow: hidden;
      margin-bottom: 13px;
      .right-item-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        .title-left {
          align-self: center;
          img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 13px;
          }
          span {
            font-size: 12px;
            vertical-align: middle;
            font-weight: 300;
            color: #1a1a1b;
          }
        }
        .title-right {
          font-size: 12px;
          font-weight: 300;
          color: #898989;
          align-self: center;
        }
      }
      .right-item-content {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 300;
        color: #1a1a1b;
        line-height: 24px;
        padding: 5px 0;
      }
      .right-item-media {
        margin-top: 20px;
        img {
          width: 150px;
          height: 200px;
          object-fit: cover;
          margin-right: 13px;
        }
      }
    }
  }
}
// popover组件
.management-popover {
  width: 80px !important;
  min-width: 50px !important;
}
.popover-title {
  img {
    width: 15px;
    height: auto;
    vertical-align: middle;
  }
}
// 弹框
.management .task-form-list {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  .task-input {
    width: calc(100% / 2 - 30px);
  }
  .remark-input {
    width: calc(100% / 2 - 40px);
  }
  .task-btn {
    width: 60px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    margin: 0;
    align-self: center;
  }
}
.comment-nomore {
  font-size: 14px;
  color: #606060;
  text-align: center;
  list-style: none;
}
</style>
